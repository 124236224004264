$(() => {
  // Hamburger
  const bodyTag = $('body')
  const hamburgerTag = $('.c-hamburger')

  hamburgerTag.on('click', function (e) {
    hamburgerTag.toggleClass('is-active')
    bodyTag.toggleClass('nav-open')
  })

  // Append Login with Facebook button next to Login button
  if ($('body').hasClass('account-login')) {
    $('#tn-login-button').wrap("<div class='js-login__button-container'></div>")
    // $('#tn-facebook-login-form').appendTo('.js-login__button-container')
  }
  if ($('body').hasClass('donate')) {
    // Target Youtube iFrames and wrap them in a container
    $('iframe[src*="youtube.com"]').each(function () {
      $(this).parent().css('height', '')
      $(this).wrap("<div class='js-youtube__container'></div>")
    })
    // Wrap from groups in a container
    // $('.form-group').wrapAll("<div class='js-donate__form-container'></div>")
  }
  // Find links in precart and turn them into buttons
  if ($('body').hasClass('precart')) {
    $('a b').each(function () {
      if ($(this).text().match('Go to cart and complete order')) {
        $(this).wrap("<button class='btn btn-default'></button>")
      }
    })
  }
  // Find donate button and add it to the end of the page
  if ($('body').hasClass('donate-contribute2')) {
    $('#tn-contribution-submit').parent().wrap("<div class='js-donate-button__container'></div>")
  }
  // Find date within precart packages, and add a comma after the month
  if ($('body').hasClass('precart')) {
    const date = $('.tn-precart-response-message .tnew-eventlisting-prod-perf-date')
    date.each(function () {
      $(this).html(function (i, str) {
        return str.replace(/(\w{3}\s+\d{1,2})(\s\d{4}\s+\d:\d\d(AM|PM))/g,
          (match, a, b) => {
            return `${a}, ${b}`
          }
        )
      })
    })
  }
})
